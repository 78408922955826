/** @jsx jsx */
import { Button, Text, jsx, Badge, Grid } from "theme-ui";
import { useCallback, useState } from "react";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import PropTypes from "prop-types";
import { DynamicField } from "sites-common/components/DynamicField";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { DefaultButton, Label, Stack, StackItem } from "office-ui-fabric-react";
import { get } from "lodash";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { daysDiff } from "@heartfulnessinstitute/react-hfn-forms/dist/utils";
import { formatSys2OdooDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import DefaultLayout from "../../layouts/dlw-mobile";

const conditions = [
  { name: "displayname", label: "Display Name is correct", type: "toggle" },
  {
    name: "photo",
    label: "Photo shows person's face clearly and looks good",
    type: "toggle",
  },
  {
    name: "practice",
    label:
      "Abhyasi has been practicising regularly and taking sittings for atleast 3 months",
    type: "toggle",
  },
];

const rejection = [
  {
    name: "notes",
    label: "Rejection Notes",
    description:
      "An email will be automatically sent to the requesting practitioner with the rejection comments mentioned here. Please articulate clearly and politely why you are rejecting and mention what actions are supposed to be taken by the requesting practitioner before applying Regular ID again.",
    type: "text",
    props: { multiline: true, rows: 5, required: true },
  },
];

const ApproveRejectBtn = ({
  id,
  action,
  onAction,
  btnText,
  discard,
  ...btnProps
}) => {
  const { fetchSrcmApi } = useFetchSrcmApi();
  const { srcmProfile } = useAuth();
  const approved_by = srcmProfile?.id;

  const doIt = useCallback(
    (v, s, f) => {
      if (action === "approve") {
        if (conditions.filter((x) => !get(v, x.name, false)).length > 0) {
          f(
            "Please confirm all the checks above.  If you answered 'No' to any of these checks, please click 'discard' and 'Reject' this request, so that a mail can be sent to the practitioner with your rejection notes!"
          );
          return;
        }
      }
      if (action === "reject") {
        if (!v.notes || v.notes.length < 10) {
          f("Please enter a valid rejection reason");
          return;
        }
      }

      const { notes = "" } = v;
      const methodParams = {
        notes,
        approval_type: "",
        approved_by,
      };
      fetchSrcmApi({
        api: `/api/v2/me/approvals/${id}/${action}/`,
        method: "POST",
        methodParams,
      })
        .then(() => {
          onAction(action);
          s();
        })
        .catch(() => {
          f("Server Request failed!");
        });
    },
    [onAction, action, id, fetchSrcmApi, approved_by]
  );

  return (
    <div sx={{ m: 2, p: 4, bg: "whitesmoke" }}>
      <DynamicForm
        formTitle={action === "approve" && "To approve this request, I confirm"}
        formFields={action === "approve" ? conditions : rejection}
        defaultValues={{}}
        onSubmit={doIt}
        onDiscard={discard}
        buttons={{
          submitButton: (
            <Button {...btnProps} sx={{ m: 2 }} type="submit">
              {btnText}
            </Button>
          ),
          discardButton: (
            <Button {...btnProps} sx={{ m: 2, bg: "grey" }}>
              Discard
            </Button>
          ),
        }}
      />
    </div>
  );
};

ApproveRejectBtn.propTypes = {
  id: PropTypes.number.isRequired,
  action: PropTypes.oneOf(["approve", "reject"]).isRequired,
  onAction: PropTypes.func.isRequired,
  btnText: PropTypes.string.isRequired,
  discard: PropTypes.func.isRequired,
};

const formFields = [
  { name: "ref", label: "Temporary/Welcome ID" },
  { name: "name", label: "Name" },
  { name: "date_of_joining", label: "Date of Starting Practice", type: "date" },
  { name: "email", label: "Email", type: "email" },
  { name: "mobile", label: "Mobile", type: "phone" },
  { name: "age", label: "Age" },
];

const colDefns = [
  [
    "Requests waiting for approval",
    (r) => {
      const [done, setDone] = useState(false);
      const [show, setShow] = useState(null);

      const discard = useCallback(() => setShow(null), []);
      const showApproval = useCallback(() => setShow("approve"), []);
      const showRejection = useCallback(() => setShow("reject"), []);

      return (
        <div sx={{ p: 1, my: 2, width: "100%", bg: "#AEBD38" }}>
          <div>
            <Badge variant="info">New Practitioner</Badge>{" "}
            <div style={{ float: "right" }}>
              <Text variant="description">{r.id}</Text>
            </div>
          </div>

          <div sx={{ mb: 2 }}>
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <StackItem>
                <img width="150" src={r.approval_data?.photo_url} alt="" />
              </StackItem>
              <StackItem>
                <DynamicForm
                  formFields={formFields}
                  defaultValues={{
                    ...r.approval_data,
                    age: Math.trunc(
                      daysDiff(
                        formatSys2OdooDate(new Date()),
                        r.approval_data.date_of_birth
                      ) / 365
                    ),
                  }}
                  printOnly
                />
                <Stack horizontal>
                  <Label>
                    <b>City:&nbsp; </b>
                  </Label>
                  <DynamicField
                    name="city"
                    type="city"
                    value={r.approval_data?.city_id}
                    label="city"
                    disabled
                  />
                </Stack>
              </StackItem>
            </Stack>

            <Grid columns={1} sx={{ pt: 1 }} gap={20}>
              <div>
                {!!done && (
                  <div>
                    {done === "reject" ? (
                      <div
                        sx={{
                          py: 1,
                          px: 3,
                          fontSize: "1.3em",
                          fontWeight: 700,
                          color: "secondary",
                          backgroundColor: "highlight",
                        }}
                      >
                        Rejected (Taken Action)
                      </div>
                    ) : (
                      <div
                        sx={{
                          py: 1,
                          px: 3,
                          fontSize: "1.3em",
                          color: "primary",
                          fontWeight: 700,
                          backgroundColor: "highlight",
                        }}
                      >
                        Approved (Taken Action)
                      </div>
                    )}
                  </div>
                )}
                {!done && !!show && (
                  <ApproveRejectBtn
                    onAction={setDone}
                    variant={show === "reject" ? "secondaryFit" : "primaryFit"}
                    id={r.id}
                    action={show}
                    btnText={show === "reject" ? "Reject" : "Approve"}
                    discard={discard}
                  />
                )}
                {!done && !show && (
                  <Stack
                    horizontal
                    sx={{ m: 2, p: 4, bg: "whitesmoke" }}
                    tokens={{ childrenGap: 10 }}
                  >
                    <DefaultButton
                      styles={buttonStyles.default}
                      text="Approve this request"
                      onClick={showApproval}
                    />
                    <DefaultButton
                      styles={buttonStyles.danger}
                      text="Reject this request"
                      onClick={showRejection}
                    />
                  </Stack>
                )}
              </div>
            </Grid>
          </div>
        </div>
      );
    },
  ],
];

const MeSearch = () => {
  return (
    <DefaultLayout>
      <div sx={{ textAlign: "left", m: 2 }}>
        <Text variant="header">My Pending Approvals </Text>
      </div>
      <SrcmSearchPage
        reduxKey="me-approvals1"
        apiParams={{
          api: `/api/v2/me/approvals/`,
          methodParams: { approval_type: "apply_perm_id" },
        }}
        defaultKey="status"
        defaultValue="pending"
        keyOptions={[{ name: "status", label: "Approval Status" }]}
        colDefns={colDefns}
        noSearchDisplay
      />
    </DefaultLayout>
  );
};

export default MeSearch;
